:root {
  --bg-color-disable-button: rgb(101, 149, 187);
  --bg-color-disable: gray;
  --bg-color-active-button-scroll-nav: rgba(25, 118, 210, 0.08);
  --bg-color-border-title: #1976d2;
  --bg-color-border-tab: #c6bebe;
  --bg-color-border-nemu: #e0e0e0;
  --bg-color-button: #166bcf;
  --bg-color-login: rgba(238, 84, 84, 0.32);
  --bg-color-dashbroad: #295669;
  --bg-color-chip1: #1f56c1;
  --bg-color-chip2: #ee5454b8;
  --bg-color-chip3: #00000080;
  --bg-color-chip4: #d97c27;
  --bg-color-chip5: #c51919;
  --bg-color-chip6: #000000;
  --bg-color-chip7: #086611cc;
  --bg-notification-title: #2e7b74;
  --bg-color-badge: rgba(23, 21, 21, 0.1);
  --bg-color-notifications-icon: #ebc351;
  --bg-nav-main: #cecece;
  --bg-notification: #cfdbe7;
  --border-text-link: #373232;
  --bg-white: white;

  --color-error: red;
  --color-border: #ccc;
  --color-border-action: #f1e3e3;
  --color-input-default: black;
  --color-title-commodity: #635d5d;
  --color-title-import: #d0afb4;
  --color-star-icon: #faaf00;
  --color-title-payshell: #c1d2b3;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-blue: #1976d2;
  --color-brown: #c5bd025c;
  --color-orange: #e37424;
  --color-green: #1c8a3b;
  --color-black: #333;
  --color-red-trip: #e44545;
  --color-waiting: #1c72d659;
  --fz-global: 14px;
  --fz-mobile: 12px;
  --color-time-notification: #0866ff;
}

* {
  box-sizing: border-box;
}

html {
  font-size: var(--fz-global);
}

body {
  height: 100%;
  font-size: var(--fz-global);
}

header.header-container {
  background-color: var(--bg-color-dashbroad) !important;
  position: fixed;
  box-shadow: none !important;
}

.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99999 !important;
}

.MuiToolbar-root {
  background-color: var(--bg-nav-main);
}

/*Driver Table*/
.page-title {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 8px;
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.banner-image {
  width: 450px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.delete-button-paid {
  position: absolute !important;
  top: 2px;
  right: 7px;
  cursor: pointer !important;
  background: var(--color-border) !important;
  padding: 4px !important;
}

.input-end {
  margin-right: 36px;
}

.apexcharts-legend-series {
  display: flex !important;
  justify-content: center !important;
  margin-right: 2rem !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.MuiAutocomplete-hasPopupIcon.css-1p6gcs-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1p6gcs-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 15px !important;
}

.notification-container {
  max-height: 550px;
  overflow-x: hidden !important;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.notifications-icon {
  color: var(--bg-color-notifications-icon);
  font-size: 36px !important;
  line-height: 0px;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3));
}

.avatar-google-map {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 5px solid var(--color-green);
}

.avatar-google-map-busy {
  border: 5px solid var(--bg-color-chip5);
}

.info-box-detail {
  background-color: var(--bg-white);
  border-radius: 1em;
  padding: 0.5em;
  font-size: 14px;
  width: auto;
  border: 2px solid var(--bg-color-button);
}

.infoBox {
  width: auto !important;
}

.text-notification {
  color: var(--color-time-notification);
  font-size: 12px;
}

.trip-span-blue strong {
  font-size: 12px;
}

.trip-span-blue span {
  color: #166bcf;
  cursor: pointer;
}

.check img {
  border-radius: 50% !important;
}

.color-blue {
  color: var(--color-blue) !important;
}

.tripId {
  cursor: pointer;
}

.card-vote {
  height: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid rgb(65, 59, 59);
  box-shadow: 0px 0px 20px 0px rgb(43, 43, 44);
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.card-vote.active {
  background-color: #4578b7;
  color: var(--color-white);
  box-shadow: 0px 0px 20px 0px rgba(69, 120, 183, 1);
}

.note-edit-trip {
  font-weight: bold;
  font-size: 12px;
}

.tab-header-width {
  width: auto;
  white-space: nowrap;
}

.custom-textfield {
  background-color: var(--color-white);
}

.custom-textfield .MuiOutlinedInput-root {
  border-color: var(--color-border);
}

.custom-textfield .MuiOutlinedInput-root:hover fieldset {
  border-color: var(--color-border);
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #3f51b5;
}

.custom-chip {
  background-color: var(--color-red-trip);
  border: 1px solid var(--color-border);
  margin: 8px;
}

.custom-textfield .MuiChip-root {
  margin: 4px;
  background: #efefef;
}

.custom-chip .MuiChip-label {
  font-size: 0.875rem;
}

.custom-autocomplete {
  background-color: var(--color-white);
  border-radius: 4px;
}

.custom-autocomplete .MuiAutocomplete-tag {
  margin: 2px;
}

.w-130 {
  width: 130px;
}

.assign-detail .container-row {
  width: 90px;
}

.w-50 {
  width: 50px;
}

.tab-data-width {
  width: auto;
}

.auto-width {
  width: auto;
}

.tab-name-width {
  min-width: 200px !important;
  max-width: 300px !important;
  overflow-wrap: break-word !important;
}

.title-notification {
  background-color: var(--bg-color-dashbroad);
  color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 8px !important;
}

.content-width {
  min-width: 250px;
  width: 40%;
}

.notification-list {
  padding: 12px !important;
  width: 360px !important;
  cursor: pointer;
}

.notification-list:hover {
  background-color: var(--bg-color-badge);
}

.driver-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.camera-alt-position {
  cursor: pointer;
  position: absolute;
  bottom: 18px;
  right: 10px;
}

.camera-alt-background {
  width: 30px;
  height: 30px;
  background-color: rgb(205, 205, 205);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-driver-id {
  width: 100%;
  aspect-ratio: 33 / 20;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.button-sx {
  padding: 6px 14px;
  min-width: 155px;
}

.image-container {
  position: relative;
  display: inline-block;
  width: 300px; /* Adjust based on your image dimensions */
  height: 150px;
}

.text-red-statictis {
  color: var(--color-red-trip);
}

.card-trip-statictis {
  height: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid rgb(65, 59, 59);
  box-shadow: 0px 0px 20px 0px rgb(43, 43, 44);
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.container-text-trip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  border: 2px solid #000;
  border-radius: 8px;
  background: #f0f0f0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.driver-form-data {
  gap: 1rem;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.driver-form-spacing {
  gap: 1.5rem;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.aspect-ratio-container {
  width: 100%;
  position: relative;
  border: solid 1px #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aspect-ratio-container::before {
  content: '';
  display: block;
  padding-top: calc(20 / 33 * 100%);
}

.round-image-container {
  position: relative;
  display: inline-block;
  line-height: 0 !important;
  cursor: pointer;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3));
}

.round-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.round-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.round-image-lock {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.delete-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.image-container:hover .delete-icon {
  display: flex;
  opacity: 1;
}

.delete-icon:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.driver-status-waitting {
  color: var(--bg-color-chip1);
  cursor: pointer;
}

.driver-status-waitting-icon {
  box-sizing: initial;
  border: 1px solid var(--bg-color-chip1);
  border-radius: 3px;
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 0.1rem;
  line-height: 0px;
}

.driver-status-waitting-icon svg {
  border: 1px solid var(----color-waiting);
  box-sizing: initial;
  letter-spacing: 0 !important;
  width: 1.5rem;
  height: 1.5rem;
}

.driver-status-disabled-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: initial;
  letter-spacing: 0 !important;
  display: flex;
}

.MuiBadge-badge {
  transform: scale(1) translate(40%, -35%) !important;
  font-size: 10px !important;
}

.button-not-hover:hover,
.button-not-hover:active,
.button-not-hover:focus {
  background-color: transparent !important;
}

.icon-badge .driver-status-disabled {
  color: var(--bg-color-disable);
}

.popup-title {
  padding: 1rem 1.5rem 0rem !important;
}

.popup-divider {
  height: 2px;
  background-color: var(--bg-color-dashbroad);
  margin: 0 1rem !important;
}

.divider {
  height: 1px;
  background-color: var(--bg-color-dashbroad);
  width: 100%;
}

.popup-text {
  font-size: 1rem !important;
  padding: 1rem 1rem 0 2rem !important;
  font-weight: 500 !important;
}

.group-button {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  gap: 1rem;
  padding: 1.5rem 1rem 1.5rem !important;
}

.status-chip-1 {
  background-color: var(--bg-color-chip1) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-2 {
  background-color: var(--bg-color-chip2) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-3 {
  background-color: var(--bg-color-chip3) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-4 {
  background-color: var(--bg-color-chip4) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-5 {
  background-color: var(--bg-color-chip5) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-6 {
  background-color: var(--bg-color-chip6) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.status-chip-7 {
  background-color: var(--bg-color-chip7) !important;
  color: var(--color-white) !important;
  min-width: 95px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  height: 22px !important;
}

.modal-dialog-trip {
  padding-bottom: 0 !important;
}

.dialog-button-action {
  padding: 1.5rem !important;
}

.modal-trip {
  border-bottom: solid 2px var(--bg-color-dashbroad);
}

.dialog-content-trip {
  padding: 0.5rem 1.5rem 0;
}

.nav-menu {
  position: fixed;
  height: 100%;
  z-index: 999;
}

.content-noti {
  cursor: pointer;
  min-height: 50px;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px;
}

.main-component {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.actions {
  position: sticky;
  right: 0;
  background-color: white;
}

.pl-24 {
  padding-left: 24px !important;
}

.border-left {
  border-left: 1px solid #eadcdc;
}

.stt {
  border-left: 1px solid #eadcdc;
  text-align: center !important;
  width: 20px !important;
}

.main-component.open {
  margin-left: 250px;
}

/* Custom Divider */
.MuiGrid-item .divider-custom {
  border-color: rgb(0 0 0 / 33%);
}

/* Custom Option of Select */
.MuiSelect-select .option-custom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--fz-global);
  line-height: 1.25;
}

.input-location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-options-location {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .input-location .MuiInputBase {
  width: 650px !important;
} */

.location-menu {
  white-space: 'break-spaces';
  border-bottom: '1px solid var(--color-border)';
}

.fz-14 input {
  font-size: var(--fz-global) !important;
}

.fz-16 {
  font-size: var(--fz-global) !important;
}

.fz-18 {
  font-size: 18px;
}

.fz-60 {
  font-size: 65px !important;
}

.image-paymend-spend {
  margin-right: 5px;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.MuiPaper-root {
  overflow-y: hidden !important;
}

.readableTime {
  color: #166bcf;
  font-weight: bold;
}

.totalAdministractive {
  text-align: center !important;
}

.MuiTableCell-root {
  font-size: var(--fz-global) !important;
}

.padding-items-12 {
  padding: 12px 0;
}

/* Custome empty data */
.custom-empty-data {
  border-top: 2px solid black;
  width: 15px;
}

.username {
  white-space: nowrap;
  width: auto;
  color: var(--color-black);
  padding-left: 8px;
  gap: 5px;
  display: flex;
  flex-direction: column !important;
}

.username > * {
  line-height: 1 !important;
}

.tab-header {
  padding: 10px 10px !important;
  width: auto;
  white-space: nowrap;
  text-align: center !important;
  font-size: var(--fz-global) !important;
}

.actions-bg .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child {
  position: sticky;
  right: 0;
  background-color: white;
}

.border-left {
  border-left: 1px solid #eadcdc;
}

.fixed-col-table {
  position: sticky;
  right: 0;
  background-color: white;
}

.container-image {
  position: relative !important;
}

.chip-detail div {
  min-width: 80px !important;
}

.custom-empty-data-debt {
  border-top: 2px solid black;
  width: 15px;
  padding: 14px;
}

/*  */
.status-order-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sticky-column {
  position: sticky !important;
  left: 0 !important;
  /* background-color: yellow !important; */
  z-index: 999 !important;
}

.apexcharts-legend-marker {
  width: 20px !important;
  height: 20px !important;
}

.MuiIconButton-root {
  padding: 2px !important;
}

.table_pagination_title {
  padding: 4px !important;
}

.actions-css {
  width: 10px !important;
}

.MuiTable-root .MuiTableCell-root {
  padding-top: 3px;
  padding-bottom: 3px;
}

.fz-text-global {
  font-size: var(--fz-global);
}

/* custom icon */

.MuiTableCell-root .error-outline {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.MuiContainer-root {
  margin-top: 0 !important;
}

/* custom scroll  */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.customer-icon-money {
  padding: 3px;
  height: 40px;
  width: 40px;
}

.total-align-col {
  text-align: right;
}

.font-size-34 {
  font-size: 34px !important;
}

.write-trip-icon {
  color: var(--color-blue);
  margin-left: 4px;
  cursor: pointer;
  font-size: 18px;
}

.fz-text-dashboard {
  font-size: 18px;
}

.fz-value-dashboard {
  font-size: 28px;
  font-weight: 800;
}

.about-offer {
  width: 100%;
  margin: 0;
  display: block;
  max-height: calc(1.4em * 7);
  overflow-y: auto;
  white-space: pre-line;
}

.fz-icon {
  height: 80px !important;
  width: 80px !important;
}

.apexcharts-legend {
  padding: 20px !important;
  left: 30px !important;
}

.graphic-container {
  min-width: 900px;
  max-width: 900px;
  overflow-x: auto;
  overflow-y: hidden;
}

.grid-item-dashboard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 16px;
  height: 170px;
  margin: 8px !important;
}

.break_space {
  white-space: break-spaces;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root.Mui-disabled.bg-color-disable {
  background-color: var(--bg-color-disable-button) !important;
}

.image-hidden {
  display: none !important;
}

.add-img {
  color: var(--color-blue);
  font-size: var(--fz-global);
}

.add-img:hover {
  cursor: pointer;
}

.apex-chart-container {
  width: 100%;
  overflow-x: auto !important;
}

.apex-chart-wrapper-6000 {
  width: 6000px;
}

.apex-chart-wrapper-max {
  width: 30000px;
}

.apex-chart-wrapper-900 {
  width: auto !important;
}

.apex-chart-wrapper-3000 {
  width: 3000px;
}

.scroll-table {
  max-height: 65vh;
  overflow-y: auto;
}

.no-hover:hover,
.no-hover:active,
.no-hover:focus {
  background-color: transparent;
  color: inherit;
  box-shadow: none;
  cursor: pointer;
}

.hiden-icon .MuiAutocomplete-endAdornment {
  display: none;
}

.text-noOption {
  padding: 20px;
}

.icon-location {
  font-size: 24px;
  margin: 0 16px;
}

.list-location .MuiButtonBase-root {
  padding-left: 0px !important;
  border-bottom: 1px solid var(--border-text-link);
}

.list-location .MuiList-root {
  max-height: 350px;
  overflow-y: auto;
}

.icon-send {
  color: var(--bg-color-chip5);
  cursor: pointer;
}

.scroll-table > .MuiTable-stickyHeader > thead > tr > th {
  min-width: 70px;
}

.add-img-text {
  text-align: center;
}

.color-red {
  color: var(--color-red-trip) !important;
}

.id-trip-strong strong {
  cursor: pointer;
  color: var(--color-red-trip);
}

.text-message-fz12 strong {
  font-size: 12px !important;
}

.id-trip-span span {
  cursor: pointer;
  color: var(--color-red-trip);
}

.MuiPaper-root .menu-action {
  height: 50px;
  border-bottom: 1px solid var(--color-border-action);
}

.color-green {
  color: green;
}

.margin-input-login {
  margin: 6px 0px !important;
  border-radius: 5px;
}

.width-170 {
  width: 170px;
}

.width-290 {
  width: 290px !important;
}

.max-width-40 {
  max-width: 40px;
}

.max-width-60 {
  max-width: 60px;
}

.max-width-80 {
  max-width: 80px;
}

.max-width-100 {
  max-width: 100px;
}

.max-width-120 {
  max-width: 100px;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-110 {
  min-width: 110px;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-130 {
  min-width: 130px;
}

.min-width-140 {
  min-width: 140px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-160 {
  min-width: 160px;
}

.min-width-170 {
  min-width: 170px;
}

.min-width-180 {
  min-width: 180px !important;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-240 {
  min-width: 240px;
}

.min-width-270 {
  min-width: 270px;
}

.min-width-300 {
  min-width: 300px;
}

.max-width-170 {
  max-width: 170px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-120 {
  max-width: 120px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-210 {
  min-width: 210px;
}

.min-width-215 {
  min-width: 215px !important;
}

.min-width-480 {
  min-width: 480px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-400 {
  max-width: 400px;
}

.break-word {
  overflow-wrap: break-word !important;
}

.modal-title {
  padding: 1rem 1rem 1rem 24px !important;
  line-height: 1 !important;
}

.no-padding {
  padding: 0 !important;
}

.dashboard-card {
  display: flex;
  padding: 1rem 0 !important;
  justify-content: space-between !important;
}

.dashboard-card > * {
  flex-grow: 1;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  align-content: space-between;
  max-width: 32% !important;
}

.dashboard-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-icon > svg {
  line-height: 1;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
}

.view-detail-dashboard {
  display: flex;
  padding: 1rem;
  gap: 2px;
  cursor: pointer;
  color: var(--color-green);
  align-items: center;
}

.view-detail-dashboard > svg {
  font-size: 2rem !important;
}

.border-button-back {
  border-bottom: 1px solid var(--bg-color-login);
  justify-content: flex-start;
}

.MuiContainer-root .bd-title {
  background-color: var(--bg-color-login);
  margin: 15px 0 20px 0px;
}

.MuiButtonBase-root-MuiTab-root {
  font-size: var(--fz-global) !important;
}

.MuiButtonBase-root
  .MuiListItemButton-root
  .MuiListItemButton-gutters
  .MuiListItemButton-root
  .MuiListItemButton-gutters {
  border-bottom: 1px solid var(--border-text-link) !important;
  border-top: 1px solid var(--border-text-link) !important;
}

.MuiContainer-root .bd-title-tab {
  background-color: var(--bg-color-login);
  margin: 8px 0 5px 0px;
}

.MuiContainer-root .bd-title-tab-debt-customer {
  background-color: var(--bg-color-login);
  margin: 25px 0 25px 0;
}

.bd-vote {
  background-color: var(--bg-color-login);
  margin: 0 2px;
}

.MuiPaper-root .border-title {
  border-bottom: 3px solid var(--bg-color-login);
  font-weight: bold;
}

.background-login {
  background-color: var(--bg-color-login) !important;
}

.vote-item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Đặt nội dung ở giữa */
}

.star-icon {
  color: var(--color-star-icon);
}

.input-login {
  background-color: white !important;
}

.logo-welcome {
  width: 20%;
  height: auto;
  border-radius: 50%;
  object-fit: contain;
}

.logo-forget {
  width: 50px;
  height: auto;
  border-radius: 50%;
  object-fit: contain;
}

.container-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px;
}

.text-hire-driver {
  font-size: 28px;
  font-family: 'Josefin Sans ' !important;
  color: var(--bg-color-dashbroad);
  margin: 0 6px;
}

.forget-pass {
  display: flex;
  justify-content: flex-end;
}

.forget-pass:hover {
  cursor: pointer;
}

.container-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  font-size: var(--fz-global) !important;
}

.required {
  color: var(--color-error);
  margin-left: 4px;
}

.fw-800 {
  font-weight: 800;
}

.avatar-img {
  width: 300px !important;
  height: 300px !important;
  object-fit: contain;
  border-radius: 8px !important;
}

.add-image-driver {
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  color: var(--bg-color-border-title);
}

.icon-suggest {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.add-img-text {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--bg-color-border-title);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.add-img-payment:hover {
  cursor: pointer;
}

.button-img-commodity {
  position: absolute !important;
  top: 0;
  right: 0;
  background-color: var(--color-white);
  opacity: 0.5;
}

.avatar-container {
  position: relative;
}

.detail-trip {
  color: blue;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.editor-form-opacity {
  pointer-events: none;
  opacity: 0.6;
}

.opacity-text-offer {
  opacity: 0.4;
  cursor: default;
}

.ql-editor {
  height: 100px !important;
  max-height: 250px !important;
  overflow-y: auto !important;
}

.total-money {
  text-align: right !important;
}

.price-money {
  text-align: right !important;
}

.province-name {
  font-weight: 600 !important;
  min-width: 180px;
}

.driver-active {
  display: flex;
  flex-grow: 0.8;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem !important;
}

.driver-active > * {
  max-width: 270px;
}

.align-center {
  display: flex !important;
  align-items: center !important;
}

.MuiPaper-root .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px !important;
}

.MuiList-root .border-bottom {
  border-bottom: 1px solid var(--color-border-action);
  height: 50px;
  margin-bottom: 0;
  padding: 0px 16px;
}

.fz-14 {
  font-size: var(--fz-global);
}

.border-form {
  border-radius: 5px;
}

.forget-pass strong {
  color: var(--color-blue);
  font-size: var(--fz-global);
  text-decoration: underline;
}

.grid-detail {
  cursor: pointer;
}

.forget-pass strong :hover {
  cursor: pointer;
}

.background-dashbroad {
  background-color: var(--bg-nav-main);
}

.text-forget {
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.text-trip-detail {
  color: var(--color-red-trip);
  font-weight: bold;
}

.text_link.active > .MuiListItemButton-root {
  background-color: #eff2f7;
}

.scroll-nav {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 91vh;
}

.header.header-container {
  position: fixed;
}

.nav-menu {
  position: fixed;
  height: 100%;
  z-index: 999;
}

.table_pagination_title {
  padding: 0px 16pxpx;
}

.text_link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.text_link.active {
  text-decoration: none;
  color: black;
  transition: color 0.3s;
}

.icon-cursor {
  cursor: pointer;
}

.code-trip-detail {
  color: var(--bg-color-border-title);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-privacy {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.test11 img {
  width: 50px;
  border-radius: 50%;
}

.img-container {
  position: relative;
}

.MuiDrawer-paper {
  background-color: var(--bg-color-dashbroad) !important;
}

.bg-notification {
  background-color: var(--bg-notification);
}

.hover-pointer {
  cursor: pointer;
}

.MuiDrawer-paper {
  background-color: var(--bg-color-dashbroad) !important;
  border-right: 3px solid var(--bg-color-dashbroad) !important;
}

.deleteButton {
  position: absolute;
  top: 3px;
  /* Điều chỉnh vị trí từ phía trên */
  right: 3px;
  /* Điều chỉnh vị trí từ phía bên phải */
  background-color: var(--color-title-commodity);
  border-radius: 50%;
  color: white;
  z-index: 1;
  cursor: pointer;
}

.MuiPaper-root .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px !important;
}

.img-complete-trip {
  width: 250px;
  height: 250px;
  object-fit: cover;
  cursor: pointer;
}

.img-payment-order {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Đảm bảo hình ảnh đầy đủ và không bị méo */
}

.center-error {
  display: 'flex';
  justify-content: 'center';
}

.title-privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bg-color-border-title);
  font-size: 21px;
}

.list-clause li {
  margin-bottom: 20px;
}

.imageContainer:hover {
  cursor: pointer;
}

.custom-icon-nav {
  min-width: unset !important;
  width: 35px;
  font-size: 20px;
}

.card-chart {
  display: flex;
  height: 600px;
  align-items: center;
  justify-content: center;
}

.button-location {
  padding: 8px 10px !important;
}

.custom-list {
  list-style-type: circle;
  padding-left: 20px;
}

.custom-list > * {
  display: flex !important;
  align-items: center !important;
  gap: 1rem;
}

.width-privacy {
  padding: 20px 140px;
  font-family: 'Roboto', sans-serif;
}

/* MEDIA REPONSIVE */

@media only screen and (max-width: 900px) {
  .fz-text-dashboard {
    font-size: 16px;
  }

  .driver-active {
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .dashboard-card > * {
    max-width: 49% !important;
  }
}

@media only screen and (max-width: 600px) {
  .MuiTableCell-root {
    font-size: var(--fz-mobile) !important;
  }

  .width-privacy {
    padding: 0 10px;
  }

  .MuiContainer-root {
    padding: 0 1rem !important;
  }

  .driver-active {
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
  }

  .driver-active > * {
    width: 100%;
    justify-content: space-between;
    max-width: 290px;
  }

  .dashboard-card > * {
    max-width: 100% !important;
  }
}
