body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.title {
  font-size: 24px;
}

.payment-policy-container {
  padding: 40px 380px;
  display: flex;
  flex-direction: column;
}

.img-payment-discount {
  width: 800px;
  height: 500px;
  object-fit: contain;
}

.img-payment-atm {
  width: 800px;
  height: 300px;
  object-fit: contain;
}

.img-card-payment {
  width: 800px;
  height: 90px;
  object-fit: cover;
}

.choosen-vnpay {
  width: 800px;
  height: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .payment-policy-container {
    padding: 5px 10px;
  }
  .img-payment-discount {
    width: 355px;
    height: 230px;
  }
  .img-payment-atm {
    width: 350px;
    height: 190px;
  }
  .img-card-payment {
    width: 340px;
    height: 105px;
    object-fit: contain;
  }
  .choosen-vnpay {
    width: 350px;
    height: 290px;
    object-fit: cover;
  }
}
